<template>
  <div>
    <div class="c-input date" :class="{ hasErr: errMsg, disabled: disabled }" @keydown.delete="clear">
      <vuejs-datepicker
        :value="inputedVal"
        :placeholder="placeholder"
        :format="'yyyy/MM/dd'"
        :language="ja"
        v-model="inputedVal"
        @selected="onSelectedHandler"
        :disabled-dates="disabledDates"
      />
      <span class="c-input__icon" style="pointer-events: none;"></span>
    </div>
    <p v-if="errMsg" class="c-input__errMsg">{{ errMsg }}</p>
  </div>
</template>

<script>
import vuejsDatepicker from "vuejs-datepicker";
import * as filters from "@/filter";
import { ja } from "vuejs-datepicker/dist/locale";

export default {
  components: {
    "vuejs-datepicker": vuejsDatepicker,
  },
  props: {
    value: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    validation: {
      type: Function,
    },
    errMsg: {
      type: String,
    },
    disabled: {
      type: Boolean
    },
    disabledDates: {
      type: Object,
    }
  },
  data() {
    return {
      inputedVal: this.value,
      isValid: false,
      ja: ja,
    };
  },
  watch: {
    value() {
      this.inputedVal = this.value;
    },
    inputedVal() {
      this.validationSet();
    },
  },
  methods: {
    changeHandler() {
      this.isValid = this.validation(this.inputedVal);
    },
    validationSet() {
      if (this.validation) {
        this.changeHandler();
      }
      this.$emit("update:isValid", this.isValid);
      if (this.isValid) {
        this.$emit("update:errMsg", "");
      }
    },
    onSelectedHandler(e) {
      this.$emit("update:value", filters.dateForDataFormat(e));
      this.$emit("change");
    },
    clear() {
      this.$emit("update:value", "");
    }
  },
  mounted() {
    this.validationSet();
  },
};
</script>
